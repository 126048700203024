import {
  OnboardingTextFieldSimple,
  useSummaryPageErrorsTranslation,
  useSummaryPageTranslation
} from '@features/onboarding-2';
import { isEmail } from '@lib/utils';
import { useValidateEmailMXRecords } from '@core/api-internal';

import { ValidateEmailArgs, Props } from './types';

const checkIfIsEmail = (email: string): boolean => {
  return isEmail.test(email);
};

const validateMXRecord = async ({
  email,
  checkIfMXRecordIsValid,
  errorMessage
}: ValidateEmailArgs) => {
  if (!email || !checkIfIsEmail(email)) return;

  const data = await checkIfMXRecordIsValid(email);

  if (!data || !data.result) return;

  return data?.result.isEmailValid ? true : errorMessage;
};

const SummaryEmailInput = ({ register, errorMessage }: Props) => {
  const { mutateAsync: checkIfMXRecordIsValid } = useValidateEmailMXRecords();
  const t = useSummaryPageTranslation();
  const tErrors = useSummaryPageErrorsTranslation();

  return (
    <OnboardingTextFieldSimple
      {...register('emailId', {
        pattern: {
          value: isEmail,
          message: tErrors('INVALID_EMAIL')
        },
        validate: async (email) =>
          validateMXRecord({
            email,
            checkIfMXRecordIsValid,
            errorMessage: tErrors('INVALID_MX_RECORD')
          }),
        required: {
          value: true,
          message: t('NO_EMAIL')
        }
      })}
      id="email"
      label={t('LABELS.EMAIL')}
      type="email"
      error={errorMessage}
      required
    />
  );
};

export default SummaryEmailInput;
