import ActionModal from '@components/ActionModal/ActionModal';
import {
  CreateQuoteBody,
  useConvertQuoteToPolicy
} from '@core/api-insurance-in-a-box';
import { useSignupSuccess } from '@core/signup-success';
import { storage } from '@features/onboarding-2';

import { useSummaryPageTranslation } from '../../../../translation';

interface PayLaterModalProps {
  isOpen: boolean;
  closeModal: () => void;
  formData: CreateQuoteBody | null;
}

const PayLaterModal = ({
  isOpen,
  closeModal,
  formData
}: PayLaterModalProps) => {
  const t = useSummaryPageTranslation();
  const { handleSignupSuccess } = useSignupSuccess();

  const { mutate: createPolicy, isLoading: isConvertToQuoteLoading } =
    useConvertQuoteToPolicy({
      onSuccess: handleSignupSuccess
    });

  const saveUserInfoToSessionStorage = (formData: CreateQuoteBody) => {
    storage().saveOnboardingStore({
      email: formData?.userInfo.emailId,
      givenName: formData?.userInfo.givenName
    });
  };

  const handlePayLater = () => {
    if (formData) {
      saveUserInfoToSessionStorage(formData);
      formData && createPolicy(formData);
    }
  };

  return (
    isOpen && (
      <ActionModal
        isLoading={isConvertToQuoteLoading}
        onPrimaryClick={handlePayLater}
        onSecondaryClick={() => closeModal()}
        title={t('PAY_LATER_MODAL.TITLE')}
        description={t('PAY_LATER_MODAL.DESCRIPTION')}
      />
    )
  );
};

export default PayLaterModal;
