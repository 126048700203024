import { useCommonQuestionsBlockContent } from '@core/content-management';

import { AccordionSection } from '../../components';
import { usePriceSuggestionPageTranslation } from '../../translation';

const CommonQuestionsSection = () => {
  const t = usePriceSuggestionPageTranslation();
  const { data } = useCommonQuestionsBlockContent();

  if (!data) {
    return null;
  }

  const questions = data?.fields.dropdownBlocks.map((item) => ({
    title: item?.fields.title ?? '',
    content: item?.fields.subtitle ?? ''
  }));

  return (
    <AccordionSection
      title={t('COMMON_QUESTIONS.TITLE')}
      data={questions ?? []}
      variant="primary"
    />
  );
};

export default CommonQuestionsSection;
