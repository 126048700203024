import { useEffect, useState } from 'react';

import { usePriceSuggestionFestivalText } from '@core/content-management';
import { useFeature } from '@core/feature-toggles';
import localStorage from '@core/storage';
import { MaxWidthContainer, storage } from '@features/onboarding-2';

import { YourPrice } from '../';
import Datepicker from '../../../../pages/PriceSuggestion/components/Datepicker';
import { usePriceSuggestionPageTranslation } from '../../../../translation';
import Block from '../../../Block';
import Text from '../../../Text';
import styles from '../../PriceSuggestionSection.module.css';
import BankIDButton from '../BankIDButton/BankIDButton';
import ConfirmCheckbox from '../ConfirmCheckbox/ConfirmCheckbox';
import useIntersectionObserver from './hooks/useIntersectionObserver';

interface PriceSuggestionBlockProps {
  monthlyPremium: number;
  discountMonthlyPremium?: number;
  selectedStartDate: Date;
  onDateChange: (date: Date) => void;
  onAuthClick: () => void;
  isLoading: boolean;
  onVisibilityChange: (isVisible: boolean) => void;
}

const PriceSuggestionBlock = ({
  monthlyPremium,
  discountMonthlyPremium,
  selectedStartDate,
  onDateChange,
  onAuthClick,
  isLoading,
  onVisibilityChange
}: PriceSuggestionBlockProps) => {
  const { campaignCode } = localStorage();
  const { onboarding } = storage();

  const { enabled: isShareInsuranceInfoConfirmationEnabled, value: codes } =
    useFeature('wow-campaign-codes');

  const shouldShowCheckbox =
    isShareInsuranceInfoConfirmationEnabled &&
    codes?.['wow-referral-codes'] &&
    codes?.['wow-referral-codes'].includes(campaignCode);

  const t = usePriceSuggestionPageTranslation();
  const ref = useIntersectionObserver(onVisibilityChange);
  const { data } = usePriceSuggestionFestivalText();
  const isActiveFestivalCampaign =
    !onboarding?.discountMonthlyPremium && campaignCode;

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!campaignCode) {
      setChecked(false);
    }
  }, [campaignCode]);

  const heading = (
    <div className={styles.headingContainer}>
      <h4>{t('HEADING')}</h4>
      <Text type="normal-tiny">
        {isActiveFestivalCampaign ? data?.fields.body : t('DESCRIPTION')}
      </Text>
    </div>
  );

  return (
    <Block variant="fourth" className={styles.priceSuggestionBlock} ref={ref}>
      <MaxWidthContainer>
        <div className={styles.priceSuggestionBlock}>
          {heading}
          <YourPrice
            price={monthlyPremium}
            discountMonthlyPremium={discountMonthlyPremium}
          />
          <div className={styles.actionsContainer}>
            <Datepicker date={selectedStartDate} onDateChange={onDateChange} />
            {shouldShowCheckbox && (
              <ConfirmCheckbox onChange={setChecked} checked={checked} />
            )}
            <BankIDButton
              onClick={onAuthClick}
              isLoading={isLoading}
              disabled={shouldShowCheckbox && !checked}
              title={t('CONTINUE')}
            />
          </div>
        </div>
      </MaxWidthContainer>
    </Block>
  );
};

export default PriceSuggestionBlock;
