import axios from 'axios';

import { createMutation } from '../store';
import { EmailRequest, GenericEmailRequest } from '../requests/email/types';

export const useSendWowEmail = createMutation({
  request: ({ email, name }: EmailRequest) =>
    axios.post('/api/email/wow', {
      email,
      name
    })
});

export const useSendSthlmFieldsEmail = createMutation({
  request: ({ email, name }: EmailRequest) =>
    axios.post('/api/email/sthlm-fields', {
      email,
      name
    })
});

export const useSendCampaignEmail = createMutation({
  request: ({ email, name, campaignCode }: GenericEmailRequest) =>
    axios.post('/api/email/campaign', {
      email,
      name,
      campaignCode
    })
});
