import { usePriceSuggestionPageTranslation } from '@features/onboarding-2';

import styles from './ConfirmCheckbox.module.css';

const ConfirmCheckbox = ({
  onChange,
  checked
}: {
  onChange: (checked: boolean) => void;
  checked: boolean;
}) => {
  const t = usePriceSuggestionPageTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    onChange(isChecked);
  };

  return (
    <label className={styles.checkboxContainer}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        className={styles.checkbox}
      />
      <span className={styles.checkboxText}>
        {t('SHARE_INSURANCE_INFO_CONFIRMATION.TEXT')}
      </span>
    </label>
  );
};

export default ConfirmCheckbox;
