import { useEffect, useRef, useState } from 'react';

import { storage, useTrackEvent } from '@features/onboarding-2';
import { useGetUserByPersonalNumber } from '@core/api';

import { UserDetails, UseUserDetailsProps, PrefillData } from './types';
import { handleSuccess, handleError } from './handlers';

export const useUserDetails = <T = unknown>({
  personalNumber,
  onError
}: UseUserDetailsProps<T>): UserDetails => {
  const { onboarding } = storage();

  const [address, setAddress] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [age, setAge] = useState('');
  const { trackEvent } = useTrackEvent();

  const prefilledUserDetailsRef = useRef<PrefillData | null>(null);

  const { refetch, isInitialLoading } = useGetUserByPersonalNumber(
    personalNumber.value
  )({
    options: {
      enabled: false,
      onError: handleError(onError),
      onSuccess: handleSuccess({
        setState: (userDetails) => {
          setAddress(userDetails.address);
          setPostalCode(userDetails.postalCode);
          setAge(userDetails.age);
        },
        prefilledUserDetailsRef,
        trackEvent,
        onError
      })
    }
  });

  useEffect(() => {
    if (
      personalNumber.isValid &&
      personalNumber.value !== onboarding?.personalNumber
    ) {
      refetch();
    }
  }, [
    onboarding?.personalNumber,
    personalNumber.isValid,
    personalNumber.value,
    refetch
  ]);

  return {
    age,
    address,
    postalCode,
    isLoading: isInitialLoading, // isLoading does not work as expected with session storage data, isInitialLoading is used instead https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries#isinitialloading
    refetch,
    setAge,
    setAddress,
    setPostalCode,
    prefilledUserDetailsRef
  };
};
