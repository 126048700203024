import React from 'react';

import { Button, ButtonVariant } from '@components/Button/Button';
import { useTranslation } from '@core/translation';
import { cn } from '@lib/utils';

type CallToActionProps = {
  title: string | React.ReactNode;
  titleClassName?: string;
  containerClassName?: string;
  descriptionClassName?: string;
  buttonTitle?: string;
  buttonVariant?: ButtonVariant;
  description?: string;
  cta?: () => void;
};

const CallToAction = ({
  title,
  titleClassName,
  containerClassName,
  descriptionClassName,
  buttonTitle,
  buttonVariant,
  description,
  cta
}: CallToActionProps) => {
  const ct = useTranslation('COMMON');
  return (
    <div className={cn('flex flex-col items-center', containerClassName)}>
      {React.isValidElement(title) ? (
        title
      ) : (
        <h1
          className={cn(
            'my-10 text-center text-xl font-medium text-white md:whitespace-pre md:text-2xl md:font-semibold',
            titleClassName
          )}
        >
          {title}
        </h1>
      )}
      {!!description && (
        <p
          className={cn(
            'mb-14 text-center text-white md:whitespace-pre md:text-2xl md:font-medium',
            descriptionClassName
          )}
        >
          {description}
        </p>
      )}
      {!!cta && (
        <div className="inline-block w-full md:w-[286px]">
          <Button variant={buttonVariant ?? 'fourth'} size="big" onClick={cta}>
            {buttonTitle || ct('SIGN_HERE')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CallToAction;
