import { useEffect, useRef } from 'react';

import { useFeature, useFeatureIsOn } from '@growthbook/growthbook-react';

import { useOnboardingData } from './useOnboardingData';
import { useSuccessCampaignEmail } from './useSuccessCampaignEmail';

export const useCheckAndSendCampaignEmail = () => {
  const hasSentEmail = useRef(false);
  const isGenericEmailEnabled = useFeatureIsOn('generic-email');
  const onboardingData = useOnboardingData();
  const { value: codes } = useFeature('wow-campaign-codes');
  const { sendCampaignEmail, sendFieldsCampaignEmail, sendWowCampaignEmail } =
    useSuccessCampaignEmail();

  useEffect(() => {
    const isValidToSend =
      !hasSentEmail.current &&
      onboardingData.email &&
      onboardingData.givenName &&
      onboardingData.campaignCode;

    if (!isValidToSend) return;

    if (isGenericEmailEnabled) {
      sendCampaignEmail({
        email: onboardingData.email,
        name: onboardingData.givenName,
        campaignCode: onboardingData.campaignCode
      });
    } else if (onboardingData.campaignCode.includes('FIELDS')) {
      sendFieldsCampaignEmail({
        email: onboardingData.email,
        name: onboardingData.givenName
      });
    } else if (codes?.['wow-codes'].includes(onboardingData.campaignCode)) {
      sendWowCampaignEmail({
        email: onboardingData.email,
        name: onboardingData.givenName
      });
    }

    hasSentEmail.current = true;
  }, [
    onboardingData,
    isGenericEmailEnabled,
    sendCampaignEmail,
    sendFieldsCampaignEmail,
    sendWowCampaignEmail,
    codes
  ]);
};
