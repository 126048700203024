import React from 'react';

import PegBackground from '@assets/images/peg-background.webp';
import { CampaignPageHero } from '@components/index';
import { useLandingPageNavigation } from '@features/landing-page';

import styles from './PegParnevikPromo.module.css';

export const PegParnevikPromo = () => {
  const { navigateToPegParnevik } = useLandingPageNavigation();

  const createTitle = () => {
    return (
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>Hellre sejf än sorry</h2>
        <h3 className={styles.subTitle}>Sejfa x Peg Parnevik</h3>
      </div>
    );
  };
  return (
    <CampaignPageHero
      title={createTitle()}
      bgImg={{
        src: PegBackground,
        alt: 'Peg Parnevik sitting on a sofa, pointing to the title of the campaign',
        className: 'object-center'
      }}
      containerClassName={styles.container}
      ctaContainerClassName="h-full justify-between items-center py-10 md:py-12"
      logoPlusCTAContentClassname="justify-between md:h-full"
      ctaButtonTitle="Läs mer"
      ctaButtonVariant="primary"
      cta={navigateToPegParnevik}
    />
  );
};
