import Background from '@assets/images/wow-landing-bg-2.webp';
import { CampaignPageHero } from '@components/index';
import {
  useLandingPageNavigation,
  useTrackEvent
} from '@features/landing-page';

const WowCampaignBlock = () => {
  const { navigateToWow } = useLandingPageNavigation();
  const { trackEvent } = useTrackEvent();

  const handleCtaClick = () => {
    trackEvent('web_go_to_wow_campaign_click');
    navigateToWow();
  };

  return (
    <CampaignPageHero
      cta={handleCtaClick}
      title={'Skaffa sejfa innan 31/5 och\ngå på Way Out West!'}
      titleClassName="text-left text-[2.25rem] leading-[2.25rem] md:text-[4.5rem] font-semibold md:font-bold md:leading-[4.5rem]"
      bgImg={{
        src: Background,
        alt: 'A woman on stage singing to the crowd',
        className: 'object-center'
      }}
      ctaContainerClassName="items-start mb-20 md:mb-0"
      ctaButtonTitle="Ta mig till kampanjen"
      containerClassName="md:h-[788px] md:max-h-[100vh]"
      ctaDescription={
        'Just nu - Teckna vår hemförsäkring för ett år så bjuder vi på valfri WOW dag!'
      }
      ctaDescriptionClassName="text-left font-semibold md:font-bold"
    />
  );
};

export default WowCampaignBlock;
