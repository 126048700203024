import { storage } from '@features/onboarding-2';

import {
  useSelectFromPriceStore,
  useSelectFromUserStore
} from '../../onboarding/stores';

export type OnboardingData = {
  campaignCode: string;
  email: string;
  givenName: string;
};

export const useOnboardingData = (): OnboardingData => {
  const { email: onboarding1Email, givenName: onboarding1GivenName } =
    useSelectFromUserStore((state) => state);
  const { campaignCode: onboarding1CampaignCode } = useSelectFromPriceStore(
    (state) => state
  );

  const onboarding2 = storage()?.onboarding || {};

  return {
    campaignCode: onboarding2.campaignCode ?? onboarding1CampaignCode,
    email: onboarding2.email || onboarding1Email,
    givenName: onboarding2.givenName || onboarding1GivenName
  };
};
