import { PropsWithChildren } from 'react';

import Head from 'next/head';

import { useDisplayChatAndCookies } from '@core/hooks';

const LandingPageLayout = ({ children }: PropsWithChildren) => {
  useDisplayChatAndCookies();

  return (
    <>
      <Head>
        <title>Sejfa</title>
        <meta property="og:title" key="title" content="Sejfa" />
        <meta property="og:type" key="type" content="website" />
        <meta property="og:locale" key="locale" content="sv_SE" />
        <meta
          property="og:image"
          key="image"
          content="https://play-lh.googleusercontent.com/s0eRm6ft-DRtz64Drj9ge7ZlTRIGSxyymXMbcgOuVz1DCE56H25G_wfOOfPyU-VPJVc"
        />
        <meta
          property="og:image:secure_url"
          content="https://play-lh.googleusercontent.com/s0eRm6ft-DRtz64Drj9ge7ZlTRIGSxyymXMbcgOuVz1DCE56H25G_wfOOfPyU-VPJVc"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:description"
          name="description"
          key="description"
          content="Välj Sejfa för en smart, flexibel och prisvärd digital hemförsäkring med utökat reseskydd och bostadsrättstillägg."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="h-[calc(100vh-96px)]">{children}</div>
    </>
  );
};

export default LandingPageLayout;
