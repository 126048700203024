import { useEffect } from 'react';

import { storage as sessionStorage } from '..';
import { useTrackEvent } from '../tracking';

const useTrackWebOrderComplete = () => {
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    const hasTrackedEvents = sessionStorage().onboarding?.isOnboardingSuccess;

    if (!hasTrackedEvents) {
      trackEvent('web_order_complete');
      sessionStorage().saveOnboardingStore({
        isOnboardingSuccess: true
      });
    }
  }, [trackEvent]);
};

export default useTrackWebOrderComplete;
