import { ApiResponse } from '@pages/api/onboarding/user/[personalNumber]';
import { useTrackEvent } from '@features/onboarding-2';
import { logger } from '@lib/logger';

import { parseUserDetails } from './parseUserDetails';
import { PrefillData } from './types';
import { isValidUserResponse } from './validators';

type HandleSuccessParams = {
  setState: (userDetails: ReturnType<typeof parseUserDetails>) => void;
  prefilledUserDetailsRef: React.MutableRefObject<PrefillData | null>;
  trackEvent: ReturnType<typeof useTrackEvent>['trackEvent'];
  onError?: (error: Error) => void;
};

export const handleSuccess =
  ({
    setState,
    prefilledUserDetailsRef,
    trackEvent,
    onError
  }: HandleSuccessParams) =>
  (response: ApiResponse) => {
    if (!isValidUserResponse(response) || !response.data) return;

    try {
      const userDetails = parseUserDetails(response.data);
      setState(userDetails);

      prefilledUserDetailsRef.current = userDetails;
      trackEvent('web_entered_personal_number_success_onboarding_2');
    } catch (error) {
      onError?.(error as Error);
    }
  };

export const handleError =
  (onError?: (error: Error) => void) => (error: Error) => {
    logger.error('Error fetching user by personal number', error);
    onError?.(error);
  };
