import { useWhenIsItSejfBlockContent } from '@core/content-management';

import { AccordionSection } from '../../components';
import { usePriceSuggestionPageTranslation } from '../../translation';

const WhenIsItSejfSection = () => {
  const t = usePriceSuggestionPageTranslation();
  const { data } = useWhenIsItSejfBlockContent();

  if (!data) {
    return null;
  }

  const questions = data?.fields.dropdownBlocks.map((item) => ({
    title: item?.fields.title ?? '',
    content: item?.fields.texts ?? ''
  }));

  return (
    <AccordionSection
      title={t('WHEN_IS_IT_SEJF.TITLE')}
      data={questions}
      variant="secondary"
    />
  );
};

export default WhenIsItSejfSection;
