import { useCallback } from 'react';

import { useFeature } from '@core/feature-toggles';
import storage from '@core/storage';
import { useOnboardingNavigation } from '@features/onboarding-2';
import { DISCOUNT_CODES as SafetyByCiliaDiscountCodes } from '@features/safety-by-cilia';

type UseSignupSuccessResult = {
  handleSignupSuccess: () => void;
};

export const useSignupSuccess = (): UseSignupSuccessResult => {
  const {
    navigateToWowSuccess,
    navigateToSafetyByCiliaSuccess,
    navigateToOnboardingSuccess
  } = useOnboardingNavigation();

  const { value: codes } = useFeature('wow-campaign-codes');

  const handleSignupSuccess = useCallback(() => {
    const { campaignCode } = storage();

    const shouldNavigateToSafetyByCiliaSuccess =
      SafetyByCiliaDiscountCodes.includes(
        campaignCode as (typeof SafetyByCiliaDiscountCodes)[number]
      );

    const shouldNavigateToWowSuccess =
      codes?.['wow-codes'] && codes?.['wow-codes'].includes(campaignCode);

    if (shouldNavigateToSafetyByCiliaSuccess) {
      navigateToSafetyByCiliaSuccess();
      return;
    }

    if (shouldNavigateToWowSuccess) {
      navigateToWowSuccess();
      return;
    }

    /* Default case -> navigate to onboarding success */
    navigateToOnboardingSuccess();
  }, [
    navigateToWowSuccess,
    navigateToSafetyByCiliaSuccess,
    navigateToOnboardingSuccess,
    codes
  ]);

  return {
    handleSignupSuccess
  };
};
