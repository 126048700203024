import { useQuery } from '@tanstack/react-query';
import { Entry } from 'contentful';

import client from './client';
import { CONTENT_ENTRY, ContentType } from './types';

type EntryData<TKey extends keyof ContentType> = {
  data?: Entry<ContentType[TKey], 'WITHOUT_UNRESOLVABLE_LINKS', string>;
  loading: boolean;
  error?: unknown;
};

export const getEntries = () => {
  client.sync({ initial: true }).then((response) => {
    return response.entries;
  });
};

const useContentfulEntry = <TKey extends keyof ContentType>(
  key: TKey
): EntryData<TKey> => {
  const getEntryData = async () => {
    return await client.withoutUnresolvableLinks.getEntry<ContentType[TKey]>(
      CONTENT_ENTRY[key]
    );
  };
  const { data, error, isInitialLoading } = useQuery({
    queryKey: [key],
    queryFn: getEntryData
  });

  return { data, loading: isInitialLoading, error };
};

export const usePriceSuggestionChatContent = () => {
  return useContentfulEntry('price_suggestion_chat');
};

export const usePriceSuggestionInsurelyChatContent = () => {
  return useContentfulEntry('price_suggestion_insurely_chat');
};
export const usePriceSuggestionSimplifiedChatContent = () => {
  return useContentfulEntry('price_suggestion_chat_simplified');
};

export const useWhenIsItSejfBlockContent = () => {
  return useContentfulEntry('when_is_it_sejf_block');
};

export const useCommonQuestionsBlockContent = () => {
  return useContentfulEntry('common_questions_block');
};

export const useSejfValueModal = () => {
  return useContentfulEntry('sejf_value_modal');
};

export const useLimitedOnboardingModal = () => {
  return useContentfulEntry('limited_onboarding_modal');
};

export const useSejfDeductibleModal = () => {
  return useContentfulEntry('sejf_deductible_modal');
};

export const useSejfTripModal = () => {
  return useContentfulEntry('sejf_trip_modal');
};

export const usePriceSuggestionEditModal = () => {
  return useContentfulEntry('price_suggestion_edit_info');
};

export const useSuperSejfModal = () => {
  return useContentfulEntry('super_sejf_modal');
};

export const usePaymentFailedModal = () => {
  return useContentfulEntry('payment_failed_modal');
};

export const useChatSections = () => {
  return useContentfulEntry('chat_section');
};

export const usePriceSuggestionFestivalText = () => {
  return useContentfulEntry('price_suggestion_festival_info');
};

export const useIdentificationScreen = () => {
  return useContentfulEntry('identification_screen');
};

export const useCancelOnboardingModal = () => {
  return useContentfulEntry('cancel_onboarding_modal');
};

export const useImportantDocumentBlock = () => {
  return useContentfulEntry('important_document_block');
};

export const useCookiesSejfa = () => {
  return useContentfulEntry('cookies_sejfa');
};

export const usePrivacySejfa = () => {
  return useContentfulEntry('privacy_sejfa');
};

export const useYourIntegrity = () => {
  return useContentfulEntry('your_integrity');
};

export const useEarlyAdopter = () => {
  return useContentfulEntry('early_adopter');
};

export const useTermsSejfa = () => {
  return useContentfulEntry('terms_sejfa');
};

export const useComplaintsSejfa = () => {
  return useContentfulEntry('complaints_sejfa');
};

export const useLfCookies = () => {
  return useContentfulEntry('cookies_lf');
};

export const useLfPrivacy = () => {
  return useContentfulEntry('privacy_lf');
};

export const useParticipatingCompanies = () => {
  return useContentfulEntry('participating_companies');
};
