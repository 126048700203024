var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dWYIvswT271ARj5eT6U1e"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import env from '@core/env-config';
import { init, shouldUseDebug, tracesSampleRate } from '@core/error-monitoring';

init({
  dsn: env.SENTRY_DSN_PUBLIC || env.SENTRY_DSN,
  // Adjust his value in production, or use tracesSampler for greater control
  tracesSampleRate: tracesSampleRate,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: shouldUseDebug,
  environment: env.ENV
});
