import { useAddressConfirmation } from './useAddressConfirmationChat';
import useCalculateQuote from './useCalculateQuote';
import { useConvertQuoteToPolicy } from './useConvertQuoteToPolicy';
import useCreateQuote from './useCreateQuote';
import { useKillswitch } from './useKillswitch';
import { usePriceSuggestionChat } from './usePriceSuggestionChat';
import { usePurchaseLimits } from './usePurchaseLimits';
import useSimulateQuote from './useSimulateQuote';
import { useSoftWall } from './useSoftWall';
import { useTemplateData } from './useTemplateData';
import { useCheckAndSendCampaignEmail } from '../../onboarding-2/hooks/useCheckAndSendCampaignEmail';

export {
  useKillswitch,
  useAddressConfirmation,
  useCalculateQuote,
  useConvertQuoteToPolicy,
  useCreateQuote,
  usePriceSuggestionChat,
  usePurchaseLimits,
  useSimulateQuote,
  useSoftWall,
  useTemplateData,
  useCheckAndSendCampaignEmail
};
