import { Button } from '@components/Button/Button';
import { useLandingPageNavigation } from '@features/landing-page';
import { cn } from '@lib/utils';
import { useTranslation } from '@core/translation';

import { useCurrentPage } from '../../../../hooks/useCurrentPage';
import styles from './OffersButton.module.css';

const OffersButton = () => {
  const t = useTranslation('LANDING_PAGE');
  const { navigateToOffers } = useLandingPageNavigation(); // Assuming there's a navigate method, adjust as needed
  const { isOnboarding } = useCurrentPage();

  return (
    <Button
      onClick={() => navigateToOffers()}
      variant="primary"
      hugged
      className={cn(styles.offersButton, isOnboarding && styles.hidden)}
    >
      {t('OFFERS_BUTTON')}
    </Button>
  );
};

export default OffersButton;
