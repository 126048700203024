import {
  ApiResponse,
  ValidResponse
} from '@pages/api/onboarding/user/[personalNumber]';

export const isValidUserResponse = (
  response: ApiResponse
): response is ValidResponse => {
  return (response as ValidResponse).data !== undefined;
};
