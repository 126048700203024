import axios from 'axios';

import { GetPaymentsRequest } from '@core/api';

import { getPoliciesList, PolicyResponse } from '../requests';
import { createQuery } from '../store';
import { GetPolicyDetailsByIdRequest } from '../requests/policies/types';

const useGetPoliciesList = createQuery(
  'getPoliciesListServer',
  getPoliciesList
);

const useGetPoliciesListServer = createQuery('getPoliciesListServer', () =>
  axios.get<PolicyResponse>('/api/profile/policies')
);

const useGetPayments = createQuery(
  'getPayments',
  (params: GetPaymentsRequest) =>
    axios.get('/api/profile/payments', {
      params
    })
);

const useGetPolicyDetailsById = createQuery(
  'getPolicyDetailsById',
  (params: GetPolicyDetailsByIdRequest) =>
    axios.get('/api/profile/policy-details-by-id', {
      params
    })
);

const policies = {
  useGetPoliciesListServer,
  useGetPoliciesList,
  useGetPayments,
  useGetPolicyDetailsById
};

export default policies;
