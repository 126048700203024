import { useRouter } from 'next/router';

import {
  ProfileButton,
  SeePriceButton,
  OffersButton
} from '@components/RootLayout/components/TopBar/components';
import SejfaLogo from '@components/SejfaLogo/SejfaLogo';

import styles from './TopBar.module.css';

const TopBar = () => {
  const { push, pathname } = useRouter();
  const isRootPath = pathname === '/';

  const handleNavigateToRoot = () => {
    if (!isRootPath) {
      push('/');
    }
  };

  return (
    <div className={styles.container}>
      <SejfaLogo onClick={handleNavigateToRoot} className={styles.sejfaLogo} />
      <div className={styles.buttonsContainer}>
        <OffersButton />
        <ProfileButton />
        <SeePriceButton />
      </div>
    </div>
  );
};

export default TopBar;
