import { useEffect } from 'react';

import Image, { StaticImageData } from 'next/image';

import Img from '@assets/images/onboarding-success.svg';
import QRCodeAppStore from '@assets/images/qr-code-app-store.svg';
import QRCodePlayStore from '@assets/images/qr-code-google-play.svg';
import { Block, Button, Icon } from '@components/index';
import { useAmbassadorSignupOffer } from '@core/api';
import { useHideCookiesButton } from '@core/hooks';
import storage from '@core/storage';
import {
  useTrackWebOrderComplete,
  useCheckAndSendCampaignEmail
} from '@features/onboarding-2';
import { logger } from '@lib/logger';
import { cn } from '@lib/utils';
import { NextPageWithLayout } from '@pages/_app';

import { resetStores } from '../stores';
import { useOnboardingTranslation } from '../translation';

const OnboardingSuccess: NextPageWithLayout = () => {
  useHideCookiesButton();
  useCheckAndSendCampaignEmail();
  useTrackWebOrderComplete();

  const t = useOnboardingTranslation();
  const { mutate: postAmbassadorSignup } = useAmbassadorSignupOffer({
    onError(error) {
      logger.error('Failed to post ambassador signup', error);
    }
  });

  useEffect(() => {
    const { referralCode, removeReferralCode } = storage();

    if (referralCode) {
      postAmbassadorSignup({ ambassadorCode: referralCode });
      removeReferralCode();
    }

    resetStores();
  }, [postAmbassadorSignup]);

  const renderAppQR = (
    src: string | StaticImageData,
    title: string,
    onClick: () => void
  ) => {
    return (
      <div className="max-h-[140px] max-w-[140px] rounded-2xl border border-greySemiLight p-2">
        <Image src={src} alt="onboarding-success-image" />
        <Button
          icon="arrow-angle-up"
          className="mt-4 h-[40px] w-full justify-start self-end bg-transparent pl-2"
          onClick={onClick}
          hugged
        >
          {title}
        </Button>
      </div>
    );
  };

  const renderAppButton = (
    title: string,
    onClick: () => void,
    type: 'primary' | 'secondary'
  ) => {
    return (
      <Block
        className={cn(
          'flex aspect-square w-full cursor-pointer flex-col justify-between rounded-2xl p-4 active:bg-success/40 active:text-black/40 md:h-[162px] md:w-[167px]',
          {
            'bg-primary': type === 'primary',
            'bg-success': type === 'secondary'
          }
        )}
        onClick={onClick}
      >
        <Icon name="arrow-angle-up" className="self-end" />
        <div className="text-2xl font-semibold">{title}</div>
      </Block>
    );
  };

  const openUrl = (url: string) => window.open(url, '_blank');

  const goToPlayStore = () =>
    openUrl('https://play.google.com/store/apps/details?id=nu.sejfa.android');

  const goToAppStore = () =>
    openUrl('https://apps.apple.com/us/app/sejfa/id6449179160');

  return (
    <div className="flex h-full flex-col gap-0.5 md:grid md:h-full md:grid-cols-2">
      <Block className="relative">
        <p className="whitespace-pre-wrap pl-4 text-[80px] font-bold text-white md:pl-12 md:text-[140px]">
          {t('SUCCESS_PAGE.HEADER')}
        </p>
        <Image
          src={Img}
          alt="onboarding-success-image"
          className="absolute bottom-0 right-0 w-full"
        />
      </Block>
      <Block className="h-auto items-center justify-center bg-black md:flex md:h-screen md:bg-white">
        <div className="flex flex-col pb-14 pt-0 md:p-24 md:pt-0">
          <p className="hidden text-4xl font-bold md:mb-0 md:block md:text-[40px]">
            {t('SUCCESS_PAGE.TITLE')}
          </p>
          <p className="font-semi-bold my-4 hidden text-xl md:my-6 md:block">
            {t('SUCCESS_PAGE.DESC')}
          </p>
          <div className="hidden space-x-9 md:flex">
            {renderAppQR(
              QRCodeAppStore,
              t('SUCCESS_PAGE.APP_STORE'),
              goToAppStore
            )}
            {renderAppQR(
              QRCodePlayStore,
              t('SUCCESS_PAGE.PLAY_STORE'),
              goToPlayStore
            )}
          </div>
          <div className="flex space-x-0.5 md:hidden md:space-x-9">
            {renderAppButton(
              t('SUCCESS_PAGE.APP_STORE_MOBILE'),
              goToAppStore,
              'secondary'
            )}
            {renderAppButton(
              t('SUCCESS_PAGE.PLAY_STORE_MOBILE'),
              goToPlayStore,
              'primary'
            )}
          </div>
        </div>
      </Block>
    </div>
  );
};

export default OnboardingSuccess;
