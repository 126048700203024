import { useCallback, useMemo } from 'react';

import { storage } from '@features/onboarding-2';
import {
  useSendCampaignEmail,
  useSendSthlmFieldsEmail,
  useSendWowEmail
} from '@core/api';

import { resetStores } from '../../onboarding/stores';

export const useSuccessCampaignEmail = () => {
  const onError = useCallback(() => {
    storage()?.clearSessionStorage();
    resetStores();
  }, []);

  const onSuccess = useCallback(() => {
    storage()?.clearSessionStorage();
    resetStores();
  }, []);

  const options = useMemo(
    () => ({ onError, onSuccess, retry: 3 }),
    [onError, onSuccess]
  );

  return {
    sendCampaignEmail: useSendCampaignEmail(options).mutate,
    sendFieldsCampaignEmail: useSendSthlmFieldsEmail(options).mutate,
    sendWowCampaignEmail: useSendWowEmail(options).mutate
  };
};
