import { ValidateEmailApiResponse } from '@pages/api/onboarding/validate-email';

import { createInternalRequest } from '../api-definition';

type ValidateRequest = {
  email: string;
};

export const validateEmailMXRecords = createInternalRequest<
  ValidateEmailApiResponse,
  ValidateRequest
>({
  path: 'api/onboarding/validate-email',
  method: 'POST'
});
