'use client';

import { useCallback, useEffect, useState } from 'react';

import { withScope } from '@sentry/nextjs';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { isInApp, isMobile } from '@core/device-detect';
import env from '@core/env-config';
import { AuthResponse } from '@lib/auth';
import { logger } from '@lib/logger';

function logAssentlyHeartbeat(callbackResponse: AuthResponse) {
  withScope((scope) => {
    scope.setLevel('info');
    logger.error(
      `useLogin info with type ${callbackResponse.type}. Response: `,
      callbackResponse,
      callbackResponse
    );
  });
}

const useBankId = (
  parentCallback: (response: AuthResponse) => void,
  onError: (respose: AuthResponse) => void
) => {
  const [token, setToken] = useState<string | null>(null);

  const isLogToAssentlyOn = useFeatureIsOn('log-to-assently');

  const authenticateWithBankId = useCallback((authToken: string) => {
    setToken(authToken);
  }, []);

  useEffect(() => {
    if (!token) return;

    const script = document.createElement('script');
    script.src = env.ASSENTLY_CORE_ID_SCRIPT_URL!;
    script.async = true;

    document.body.appendChild(script);

    const _isInApp = isInApp();
    const _isMobile = isMobile();
    const autoStart = _isMobile && !_isInApp;

    script.onload = () => {
      if (window.coreid_client) {
        window.coreid_client.init({
          config: {
            allowedEids: ['se-bankid'],
            mode: 'auth',
            language: 'sv',
            provider: 'se-bankid',
            providerSettings: {
              'se-bankid': {
                autoStart,
                redirectMode: 'never'
              }
            }
          },
          token: `${token}`,
          callback: function (callbackResponse: AuthResponse) {
            if (callbackResponse.type === 'authenticated') {
              parentCallback(callbackResponse);
              isLogToAssentlyOn && logAssentlyHeartbeat(callbackResponse);
              window.coreid_client.close();
            } else if (
              ['failed', 'cancelled', 'error'].includes(callbackResponse.type)
            ) {
              onError(callbackResponse);
              window.coreid_client.close();
            } else {
              isLogToAssentlyOn && logAssentlyHeartbeat(callbackResponse);
            }
          }
        });

        window.coreid_client.start();
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [token, onError, parentCallback]);

  return {
    authenticateWithBankId
  };
};

export default useBankId;
