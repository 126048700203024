import { useRouter } from 'next/router';

export const useLandingPageNavigation = () => {
  const router = useRouter();

  const navigateToOnboarding = (campaignCode?: string) => {
    if (campaignCode) {
      router.push(`/onboarding?campaign=${campaignCode}`);
    } else {
      router.push('/onboarding');
    }
  };

  const navigateToLogin = () => {
    router.push('/login');
  };

  const navigateToOffers = () => {
    router.push('/erbjudanden');
  };

  const navigateToProfile = () => {
    router.push('/profil');
  };

  const navigateToRoot = () => {
    router.replace('/');
  };

  const navigateToWow = () => {
    router.push('/wow');
  };

  const navigateToPegParnevik = () => {
    router.push('/pegparnevik');
  };

  return {
    navigateToLogin,
    navigateToOffers,
    navigateToOnboarding,
    navigateToPegParnevik,
    navigateToProfile,
    navigateToRoot,
    navigateToWow
  };
};
