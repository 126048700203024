import { differenceInYears } from 'date-fns';

import { ValidResponse as ValidUserResponse } from '@pages/api/onboarding/user/[personalNumber]';

const calculateAge = (birthDateString: string): string => {
  const birthDate = new Date(birthDateString);
  const today = new Date();
  return differenceInYears(today, birthDate).toString();
};
const parseAddress = (
  address: NonNullable<
    ValidUserResponse['data']
  >['populationRegistrationAddress'][0]['swedishAddress']
) => {
  return {
    address: address.deliveryAddress1 || address.deliveryAddress2,
    postalCode: address.zipCode
  };
};
export const parseUserDetails = (
  data: NonNullable<ValidUserResponse['data']>
) => {
  const { details, populationRegistrationAddress } = data;
  const address = populationRegistrationAddress[0]?.swedishAddress;
  const birthDateString = details[0]?.birth?.birthDate;

  if (!address || !birthDateString) {
    throw new Error('User details missing');
  }

  const { address: parsedAddress, postalCode } = parseAddress(address);
  const age = calculateAge(birthDateString);

  return { address: parsedAddress, postalCode, age };
};
