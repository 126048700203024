import { createSejfaRequest } from '../../api-definition';
import { EmailRequest, EmailResponse, GenericEmailRequest } from './types';

export const sendWowEmail = createSejfaRequest<EmailResponse, EmailRequest>({
  path: 'email/wow',
  method: 'POST'
});

export const sendSthlmFieldsEmail = createSejfaRequest<
  EmailResponse,
  EmailRequest
>({
  path: 'email/sthlm-fields',
  method: 'POST'
});

export const sendSuccessEmail = createSejfaRequest<
  EmailResponse,
  GenericEmailRequest
>({
  path: 'email/campaign',
  method: 'POST'
});
