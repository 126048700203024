import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { CheckSimulationStatusResponse } from '@core/api';
import { logger } from '@lib/logger';

export const useOnboardingNavigation = () => {
  const router = useRouter();

  const navigateToWowSuccess = useCallback(() => {
    router.push('/wow-success');
  }, [router]);

  const navigateToSafetyByCiliaSuccess = useCallback(() => {
    router.push('/safety-by-cilia/success');
  }, [router]);

  const navigateToPriceSuggestion = (
    quoteData: CheckSimulationStatusResponse['result']
  ) => {
    const { monthlyPremium } = quoteData;
    if (!monthlyPremium) {
      logger.warn('No monthly premium available');
      return;
    }

    router.push('/onboarding/price-suggestion');
  };

  const navigateToOnboardingSuccess = useCallback(() => {
    router.push('/onboarding/success');
  }, [router]);

  return {
    navigateToPriceSuggestion,
    navigateToSafetyByCiliaSuccess,
    navigateToWowSuccess,
    navigateToOnboardingSuccess
  };
};
