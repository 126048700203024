import { useEffect } from 'react';

const getElements = () => ({
  chatButton: document?.getElementById('front-button-container-0'),
  chat: document?.getElementById('chat-container'),
  cookiesButton: document?.getElementById('ot-sdk-btn-floating')
});

export const useDisplayChatAndCookies = () => {
  useEffect(() => {
    const elements = getElements();

    if (elements.chatButton && elements.chat) {
      elements.chatButton.style.display = 'block';
      elements.chat.style.display = 'block';
    }

    if (elements.cookiesButton) {
      elements.cookiesButton.style.display = 'block';
    }

    return () => {
      const cleanupElements = getElements();

      if (cleanupElements.chatButton && cleanupElements.chat) {
        cleanupElements.chatButton.style.display = 'none';
        cleanupElements.chat.style.display = 'none';
      }
    };
  }, []);
};
